import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  FormText,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';
import DateInput from '../../commons/DateInput';
import ExtraInvoicesTabel from './ExtraInvoicesTabel';
import { useLoading } from '../../commons/Loading';
import axios from 'axios';
import { useAuth } from '../../commons/Auth';

function ModalNoInvoice(props) {
  const {
    data = {},
    onConfirm = () => {},
    year,
    fetchTotalInvoices = () => {},
  } = props;
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [{ token, profile = {} } = {}] = useAuth();
  const [modal, setModal] = useState(false);
  const [modalReport, setModalReport] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [accountability, setAccountability] = useState(undefined);
  const [formError, setFormError] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const toggle = () => {
    setModal(!modal);
    setAccountability(undefined);
    setFormError(false);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!accountability) return setFormError(true);
    if (accountability.getFullYear() > currentYear) {
      toggle();
      return alert.error(t('INVOICES:NO_INVOICE_DATE_ERR'));
    }
    if (
      accountability.getFullYear() === currentYear &&
      accountability.getMonth() > currentMonth
    ) {
      toggle();
      return alert.error(t('INVOICES:NO_INVOICE_DATE_ERR'));
    }
    onConfirm({
      supplier: data.profileId,
      month: accountability.getMonth() + 1,
      year: accountability.getFullYear(),
    });
    toggle();
  };

  const toggleReport = () => {
    setModalReport(!modalReport);
  };
  const listenForEscapeEvent = () => {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  };

  const [totExtraGross, setTotExtraGross] = useState(0);
  let totalExtraGross = 0;
  const fetchInvoices = async () => {
    showLoading();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices-by-month/year/${data.profileId}`,
      {
        headers: { Authorization: token },
        params: { year },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    for (const invoice of response.data) {
      totalExtraGross = totalExtraGross + invoice?.net * 0.15;
    }
    setTotExtraGross(totalExtraGross.toFixed(2));
    setInvoices(
      response.data.map((item) => {
        return { ...item, extraGross: item?.net * 0.15 };
      })
    );
    hideLoading();
  };

  const confirmPreview = async () => {
    showLoading();
    const selectedInvoices = invoices.map((elem) => String(elem._id));
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${data.profileId}/invoices-by-admin`,
      {
        selectedInvoices,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));

    setInvoices([]);
    toggleReport();
    fetchTotalInvoices();
  };

  return (
    <React.Fragment>
      <div className="flex-check">
        <BtnModalTable
          type="button"
          onClick={toggle}
          style={{ marginRight: '10px' }}
        >
          {t('INVOICES:NO_INVOICE_BUTTON')}
        </BtnModalTable>

        {!profile._id && (
          <BtnModalTable
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              toggleReport();
              fetchInvoices();
            }}
          >
            {t('INVOICES:REPORT_EVERYTHING')}
          </BtnModalTable>
        )}
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('INVOICES:NO_INVOICE_THIS_MONTH')}
        </ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <div className="max-width-form">
              <FormGroup>
                <DateInput
                  className="text-center"
                  name="accountability"
                  value={accountability && accountability.toISOString()}
                  showOnlyMonth
                  setMidday
                  returnDateObject
                  onChange={(accountability) => {
                    setAccountability(accountability);
                  }}
                />
                {formError && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
              </FormGroup>
            </div>
            <p>{t('COMMON:CONFIRM_WARNING')}</p>
            <p>{t('INVOICES:NO_INVOICE_THIS_MONTH_WARNING')}</p>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={modalReport}
        className="new-insurance-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggleReport}>
          Anteprima Fatture da Rendicontare
          <br />
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Totale da rendicontare: € {totExtraGross}
            </h5>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Fatture da Rendicontare: {invoices.length}
            </h5>
          </div>
          {invoices.length > 0 ? (
            <ExtraInvoicesTabel
              data={invoices}
              onFilter={() => {}}
              query={{
                limit: invoices.length,
                offset: 0,
              }}
              total={invoices.length}
            />
          ) : (
            <div style={{ textAlign: 'center' }}>
              {' '}
              Non ci sono fatture da rendicontare{' '}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed
            type="button"
            className="uppercase"
            onClick={toggleReport}
          >
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          {invoices.length > 0 && (
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={confirmPreview}
            >
              Conferma Rendicontazione
            </BtnOutlineGreen>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default ModalNoInvoice;
