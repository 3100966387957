import React, { useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { BtnOutlineGreen, ContFlexRight } from '../../../styled-components';
import {
  FISCAL_CODE_REGEX,
  POSTAL_CODE_REGEX,
  PROVINCE_REGEX,
} from '../../../lib/regex';
import _ from 'lodash';
import AdministratorRow from './AdministratorRow';
import { CERTIFIER_TYPE } from '../../../App';
import Autosuggest from '../../../commons/Autosuggest';

const CondoInfoPresentational = ({
  defaultValues,
  checkVatCode,
  onConfirm,
  fetchCertifiers,
}) => {
  const [t] = useTranslation('CONDOS');
  const alert = useAlert();
  const [{ isAdmin, profile: currentProfile = {} } = {}] = useAuth();

  const INITIAL_CONDO_STATE = useMemo(
    () => ({
      admins: defaultValues.admins || [],
      name: defaultValues.name,
      vatCode: defaultValues.vatCode,
      street: defaultValues.street,
      postalCode: defaultValues.postalCode,
      city: defaultValues.city,
      province: defaultValues.province,
      manufacturedUnitNumber: defaultValues.manufacturedUnitNumber,
      studioNumber: defaultValues.studioNumber,
      studioManager: defaultValues.studioManager || '',
      certifier: defaultValues.certifier || { _id: '', businessName: '' },
    }),
    [defaultValues]
  );

  const INITIAL_ADMIN = {
    _id: currentProfile._id,
    businessName: currentProfile.businessName,
  };
  const [formValues, setFormValues] = useState({
    ...INITIAL_CONDO_STATE,
    admin: INITIAL_ADMIN,
  });

  const [formErrors, setFormErrors] = useState({
    admins: [],
    name: false,
    vatCode: false,
    street: false,
    postalCode: false,
    city: false,
    province: false,
    manufacturedUnitNumber: false,
    invalidManufacturedUnitNumber: false,
  });

  function onInputChange({ target, uppercase }) {
    setFormValues((state) => ({
      ...state,
      [target.name]: uppercase ? target.value?.toUpperCase() : target.value,
    }));
  }

  const onCondoUpdate = async () => {
    const {
      name,
      vatCode,
      street,
      postalCode,
      city,
      province,
      admins,
    } = formValues;

    const errors = {};

    if (!name) errors.name = true;
    if (!manufacturedUnitNumber) errors.manufacturedUnitNumber = true;
    if (manufacturedUnitNumber && manufacturedUnitNumber <= 0)
      errors.invalidManufacturedUnitNumber = true;
    if (!vatCode) errors.vatCode = true;
    if (vatCode && !FISCAL_CODE_REGEX.test(vatCode))
      errors.vatCodeFormat = true;
    if (!street) errors.street = true;
    if (!postalCode) errors.postalCode = true;
    if (postalCode && !POSTAL_CODE_REGEX.test(postalCode))
      errors.postalCodeFormat = true;
    if (!city) errors.city = true;
    if (!province) errors.province = true;
    if (province && !PROVINCE_REGEX.test(province))
      errors.provinceFormat = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    try {
      const { isDuplicated } = await checkVatCode(formValues.vatCode);
      if (isDuplicated && formValues.vatCode !== defaultValues.vatCode)
        alert.error('P.Iva/Codice FIscale é giá esistente!');
      else {
        let admins = formValues.admins.map((elem) => ({
          id: elem.id,
          startDate: elem.startDate,
          endDate: elem.endDate,
        }));
        if (!isAdmin) {
          admins = admins.filter((elem) => elem.id === currentProfile?._id);
        }
        onConfirm(
          {
            ..._.omit(formValues, ['currentAdmin', 'admin']),
            admins,
          },
          defaultValues._id
        );
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const {
    name,
    vatCode,
    street,
    postalCode,
    city,
    province,
    manufacturedUnitNumber,
    studioNumber,
    studioManager,
    certifier,
  } = useMemo(() => formValues, [formValues]);

  useEffect(() => {
    if (INITIAL_CONDO_STATE.admins?.length > 0) {
      setFormErrors((oldErrors) => {
        const adminErrors = Array.from(INITIAL_CONDO_STATE.admins, () => ({
          startDate: false,
          endDate: false,
          businessName: false,
          _id: false,
        }));

        INITIAL_CONDO_STATE.admins.forEach((_admin, idx) => {
          adminErrors[idx] = {
            businessName: !_admin.businessName,
            startDate: !_admin.startDate,
            endDate: !_admin.endDate,
            id: !_admin.id,
          };
        });
        return { ...oldErrors, admins: adminErrors };
      });
    }
  }, []);

  function onCertiferSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      certifier: { _id, businessName },
    }));
  }
  const renderCertiferSuggestion = ({ businessName = '' }) => businessName;
  const getCertiferSuggestionValue = ({ businessName = '' }) => businessName;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onCondoUpdate(formValues);
      }}
    >
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col xs="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('NAME')} *</Label>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
                {formErrors.name && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('CONDOS:VAT_CODE')} *</Label>
                <Input
                  type="text"
                  name="vatCode"
                  value={vatCode}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
                {formErrors.vatCode && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
                {formErrors.vatCodeFormat && (
                  <FormText className="error-message">
                    {t('COMMON:INVALID_FISCAL_CODE_TAXCODE')}
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="8" md="6">
              <FormGroup>
                <Label>{t('STREET')} *</Label>
                <Input
                  type="text"
                  name="street"
                  value={street}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
                {formErrors.street && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('CITY')} *</Label>
                <Input
                  type="text"
                  name="city"
                  value={city}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
                {formErrors.city && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('POSTAL_CODE')} *</Label>
                <Input
                  type="text"
                  name="postalCode"
                  value={postalCode}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
                {formErrors.postalCode && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
                {formErrors.postalCodeFormat && (
                  <FormText className="error-message">
                    {t('CONDOS:POSTAL_CODE_FORMAT_ERROR')}
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('PROVINCE')} *</Label>
                <Input
                  type="text"
                  name="province"
                  value={province}
                  onChange={({ target }) =>
                    onInputChange({ target, uppercase: true })
                  }
                  placeholder="es. MI"
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
                {formErrors.province && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
                {formErrors.provinceFormat && (
                  <FormText className="error-message">
                    {t('CONDOS:PROVINCE_FORMAT_ERROR')}
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('MAN_UNIT_NUMBER')} *</Label>
                <Input
                  type="number"
                  name="manufacturedUnitNumber"
                  value={manufacturedUnitNumber}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
                {formErrors.manufacturedUnitNumber && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
                {formErrors.invalidManufacturedUnitNumber && (
                  <FormText className="error-message">
                    {t('CONDOS:INVALID_MAN_UNIT_NUMBER')}
                  </FormText>
                )}
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('STUDIO_NUMBER')}</Label>
                <Input
                  type="number"
                  name="studioNumber"
                  value={studioNumber}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('STUDIO_MANAGER')}</Label>
                <Input
                  type="text"
                  name="studioManager"
                  value={studioManager}
                  onChange={onInputChange}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('CERTIFIER')}</Label>
                <Autosuggest
                  placeholder="Cerca un certificatore..."
                  onFetch={fetchCertifiers}
                  onSelect={onCertiferSuggestionSelect}
                  renderSuggestion={renderCertiferSuggestion}
                  getSuggestionValue={getCertiferSuggestionValue}
                  value={certifier.businessName}
                  disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                  onChange={(businessName) => {
                    if (businessName.length > 0) {
                      setFormValues((state) => ({
                        ...state,
                        certifier: { ...certifier, businessName },
                      }));
                    } else {
                      setFormValues((state) => ({
                        ...state,
                        certifier: { _id: '', businessName: '' },
                      }));
                    }
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <p className="text-bold mb-2">{t('MANAGEMENT_PERIOD')}:</p>
            </Col>
          </Row>
          {formValues.admins
            .map((_admin) => ({
              ..._admin,
              startDate: new Date(_admin.startDate),
            }))
            .sort((a, b) => b.startDate - a.startDate)
            .map((_admin, idx) => (
              <AdministratorRow
                admin={_admin}
                disabled={currentProfile.type === CERTIFIER_TYPE.CERTIFIER}
                onChangeStartDate={(startDate, id) => {
                  const admins = formValues.admins;
                  const untouchedAdmins = admins.filter(
                    (elem) => elem.id !== id
                  );
                  const currentAdmin = admins.find((elem) => elem.id === id);
                  setFormValues((oldData) => ({
                    ...oldData,
                    admins: [
                      ...untouchedAdmins,
                      {
                        ...currentAdmin,
                        startDate,
                      },
                    ],
                  }));
                }}
                onChangeEndDate={(endDate, id) => {
                  const admins = formValues.admins;
                  const untouchedAdmins = admins.filter(
                    (elem) => elem.id !== id
                  );
                  const currentAdmin = admins.find((elem) => elem.id === id);

                  setFormValues((oldData) => ({
                    ...oldData,
                    admins: [
                      ...untouchedAdmins,
                      {
                        ...currentAdmin,
                        endDate,
                      },
                    ],
                  }));
                }}
                idx={idx}
                errors={formErrors}
              />
            ))}
        </TabPane>
      </TabContent>

      {currentProfile.type !== CERTIFIER_TYPE.CERTIFIER && (
        <ContFlexRight style={{ marginTop: '1rem' }}>
          <BtnOutlineGreen type="submit" className="uppercase">
            {t('COMMON:CONFIRM')}
          </BtnOutlineGreen>
        </ContFlexRight>
      )}
    </Form>
  );
};

export default CondoInfoPresentational;
