import React from 'react';

function MainteinerField({ value, maxLength }) {
  if (!value) return <>-</>;

  if (value.length === 1) {
    return <>{value[0].maintainer ? value[0].maintainer.businessName : '-'}</>;
  } else if (value.length > 0) {
    return <>{value.length}</>;
  }

  return <>-</>;
}

export default MainteinerField;
