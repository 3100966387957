import React from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanField, DataTable, Field } from '../../commons/DataTable';
import ActionsField from './ActionsField';
import ActionsFieldRegister from './ActionsFieldRegister';
import MainteinerField from '../../commons/DataTable/MaintainerField';
import TypeMainteinerField from '../../commons/DataTable/TypeMainteinerField';

function ActivitiesDetailTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  profile,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Nome Condominio" source="name" className="text-left" />
      <Field title="P.IVA/Codice Fiscale" source="vatCode" />
      <Field title="Indirizzo" source="street" className="text-left" />
      <Field title="CAP" source="postalCode" className="text-left" />
      <Field title="PV" source="province" className="text-left" />
      <BooleanField title="Sotto Contratto" source="hasContract" />

      <MainteinerField
        title="Manutentore"
        source="contracts"
        className="text-left"
      />
      <TypeMainteinerField
        title="Tipologia Manutentore"
        source="contracts"
        className="text-left"
      />

      <ActionsField
        title="Contratti del condominio"
        profile={profile}
        data={data}
      />
      <ActionsFieldRegister
        title="Registra contratto"
        profile={profile}
        data={data}
      />
    </DataTable>
  );
}

export default ActivitiesDetailTable;
