import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import '../MyProfile.scss';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import regions from '../../../lib/helpers/regions';
import { URL_REGEX } from '../../../lib/helpers/url';
import { ProfileTypes } from '../../../lib/constants';
import { refactoringAdminLevels } from '../../../lib/helpers/refactoringAdminLevels';
import Select from 'react-select';

const MyProfilePresentational = ({
  data = {},
  managers = [],
  activities = [],
}) => {
  const [t] = useTranslation('CONTACTS');
  const { register, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [selectedActivities, setSelectedActivities] = useState(
    data.activities
      ? data.activities.map((activity) =>
          activity._id ? activity._id : activity
        )
      : []
  );

  const options = activities.map((activity) => ({
    value: activity._id,
    label: activity.name,
  }));

  const handleChange = (selectedOptions) => {
    setSelectedActivities(selectedOptions.map((option) => option.value));
  };

  watch(['region']);

  function renderRegions() {
    return regions.map((region, index) => (
      <option key={index} value={region.nome}>
        {region.nome}
      </option>
    ));
  }

  function renderDistricts() {
    const currentRegion = data.region;
    const { capoluoghi = [] } =
      regions.find((r) => r.nome === currentRegion) || {};

    if (capoluoghi.length === 0) return null;

    return capoluoghi.map((district, index) => (
      <option key={index} value={district}>
        {district}
      </option>
    ));
  }

  function openUrl(url = '') {
    if (url && url.match(URL_REGEX)) window.open(url, '_blank');
  }

  return (
    <Form>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col sm="12" md="6" lg="3">
          <FormGroup>
            <Label>{t('CUSTOMER_TYPE')}</Label>
            <CustomInput
              type="select"
              id="exampleCustomSelect"
              name="type"
              value={data.type || ''}
              disabled
            >
              {ProfileTypes.map((type) => (
                <option key={type} value={type}>
                  {t(refactoringAdminLevels(type))}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm="12" md="6" lg="6">
          <FormGroup>
            <Label>{t('BUSINESS_NAME')}</Label>
            <Input
              type="text"
              name="businessName"
              defaultValue={data.businessName}
              disabled
            />
          </FormGroup>
        </Col>
        <Col sm="12" md="6" lg="6">
          <FormGroup>
            <Label>{t('NAME')}</Label>
            <Input
              type="text"
              name="name"
              maxLength="50"
              defaultValue={data.name}
              disabled
            />
          </FormGroup>
        </Col>
        {data.type !== 'CERTIFIER' && (
          <>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('ADDRESS')}</Label>
                <Input
                  type="text"
                  name="address"
                  maxLength="100"
                  defaultValue={data.address}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('CITY')}</Label>
                <Input
                  type="text"
                  name="city"
                  defaultValue={data.city}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('REGION')}</Label>
                <CustomInput
                  id="regionSelect"
                  type="select"
                  name="region"
                  innerRef={register}
                  value={data.region || ''}
                  disabled
                >
                  <option value="">-</option>
                  {renderRegions()}
                </CustomInput>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('PROVINCE')}</Label>
                <CustomInput
                  id="districtSelect"
                  type="select"
                  name="district"
                  value={data.district}
                  disabled
                >
                  <option value="">-</option>
                  {renderDistricts()}
                </CustomInput>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('CAP')}</Label>
                <Input
                  type="number"
                  name="cap"
                  defaultValue={data.cap}
                  disabled
                />
              </FormGroup>
            </Col>
          </>
        )}
        <Col sm="12" md="6" lg="4">
          <FormGroup>
            <Label>{t('VAT')}</Label>
            <Input
              type="text"
              name="taxCode"
              maxLength="20"
              defaultValue={data.taxCode}
              disabled
            />
          </FormGroup>
        </Col>
        {data.type !== 'CERTIFIER' && (
          <>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('FISCAL_CODE')}</Label>
                <Input
                  type="text"
                  name="fiscalCode"
                  maxLength="20"
                  defaultValue={data.fiscalCode}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('CELL')}</Label>
                <Input
                  type="text"
                  name="cell"
                  maxLength="20"
                  defaultValue={data.cell}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('PHONE')}</Label>
                <Input
                  type="text"
                  name="phone"
                  maxLength="20"
                  defaultValue={data.phone}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('EMAIL')}</Label>
                <Input
                  type="email"
                  name="emailReference"
                  maxLength="320"
                  defaultValue={data.emailReference}
                  disabled
                />
              </FormGroup>
            </Col>
          </>
        )}

        <Col sm="12" md="6" lg="4">
          <FormGroup>
            <Label>{t('EMAIL_COMUNICATION')}</Label>
            <Input
              type="email"
              name="emailCommunication"
              maxLength="320"
              defaultValue={data.emailCommunication}
              disabled
            />
          </FormGroup>
        </Col>
        {data.type !== 'CERTIFIER' && (
          <>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('EMAIL_PEC')}</Label>
                <Input
                  type="email"
                  name="emailPec"
                  maxLength="320"
                  defaultValue={data.emailPec}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('SDI_CODE')}</Label>
                <Input
                  type="text"
                  name="sdiCode"
                  className="uppercase"
                  defaultValue={data.sdiCode}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('ID_EXTRA')}</Label>
                <Input
                  type="text"
                  name="gabettiId"
                  maxLength="50"
                  defaultValue={data.gabettiId}
                  disabled
                />
              </FormGroup>
            </Col>
          </>
        )}

        <Col sm="12" md="6" lg="4">
          <FormGroup>
            <Label>{t('STUDIO_MANAGER')}</Label>
            <CustomInput
              id="regionSelect"
              type="select"
              name="studioManager"
              innerRef={register}
              value={data.studioManager}
              disabled={true}
            >
              <option value="">-</option>
              {managers.map((manager, i) => (
                <option key={i} value={manager._id}>
                  {manager.name}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
        </Col>
        {data.type === 'SUPPLIER' && (
          <Col sm="12" md="6" lg="4">
            <FormGroup>
              <Label>{t('ACTIVITIES')}</Label>
              <Select
                id="activities"
                name="activities"
                isMulti
                disabled={true}
                value={options.filter((option) =>
                  selectedActivities.includes(option.value)
                )}
                onChange={handleChange}
                options={options}
                placeholder={t('NO_ACTIVITIES')}
                className="react-select-disabled"
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      {data.type !== 'CERTIFIER' && (
        <Row>
          <Col sm="12" md="6" lg="4">
            <FormGroup>
              <Label>{t('ID_AFFILIATE')}</Label>
              <Input
                type="text"
                name="affiliationId"
                maxLength="50"
                defaultValue={data.affiliationId}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default MyProfilePresentational;
