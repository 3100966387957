import React from 'react';
import { Col, CustomInput, FormGroup, Row } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import {
  BtnDisabled,
  BtnRadiusWhite,
  ContFlexLeft,
  ContPaginationPage,
  H4Styled,
  H5Styled,
  SecondaryBtnModalTable,
} from '../../styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import variables from '../../variables';
import { useTranslation } from 'react-i18next';
import AgreementDetailTable from './AgreementDetailTable';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import Footer from '../../commons/Footer';
import AgreementAttachmentsTable from './AgreementAttachmentsTable';

const AgreementDetailsPresentational = ({
  data,
  total,
  offset,
  limit,
  onFilter,
  profile,
  query,
}) => {
  const [t] = useTranslation('AGREEMENTS');

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:AGREEMENTS')}
            icon={<IcnDocument fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <Row style={{ marginBottom: '10px' }}>
          <Col sm="12" md="6">
            <ContFlexLeft>
              <Link to={`/agreements`}>
                <BtnRadiusWhite>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>
              </Link>
              <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
            </ContFlexLeft>
          </Col>
        </Row>

        <Row style={{ justifyContent: 'space-between' }}>
          <Col
            sm="12"
            md="3"
            style={{
              color: `${variables.primary}`,
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            {data.name}
          </Col>
          <Col sm="12" md="3">
            <BtnDisabled
              type="button"
              disabled={true}
              style={{ width: '100%', fontSize: '15px', padding: '6px 8px' }}
            >
              {t('DOWNLOAD')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-box-arrow-down"
                viewBox="0 0 16 16"
                style={{ margin: '0', marginLeft: '3px' }}
              >
                <path
                  fill-rule="evenodd"
                  d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"
                />
                <path
                  fill-rule="evenodd"
                  d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"
                />
              </svg>
            </BtnDisabled>

            {/* <SecondaryBtnModalTable
              type="button"
              style={{ width: '100%', fontSize: '14px', padding: '6px 8px' }}
            >
              {t('DOWNLOAD')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-box-arrow-down"
                viewBox="0 0 16 16"
                style={{ margin: '0', marginLeft: '3px' }}
              >
                <path
                  fill-rule="evenodd"
                  d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"
                />
                <path
                  fill-rule="evenodd"
                  d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"
                />
              </svg>
            </SecondaryBtnModalTable> */}
          </Col>

          <Col sm="12" md="3">
            <BtnDisabled
              type="button"
              disabled={true}
              style={{ width: '100%', fontSize: '15px', padding: '6px 8px' }}
            >
              {t('NOTIFY')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil-fill"
                viewBox="0 0 16 16"
                style={{ margin: '0', marginLeft: '3px' }}
              >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
              </svg>
            </BtnDisabled>

            {/* <SecondaryBtnModalTable
              type="button"
              style={{ width: '100%', fontSize: '15px', padding: '6px 8px' }}
            >
              {t('NOTIFY')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pencil-fill"
                viewBox="0 0 16 16"
                style={{ margin: '0', marginLeft: '3px' }}
              >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
              </svg>
            </SecondaryBtnModalTable> */}
          </Col>
          <Col sm="12" md="3">
            <SecondaryBtnModalTable
              type="button"
              style={{ width: '100%', fontSize: '15px', padding: '6px 8px' }}
            >
              {t('COMMENT')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chat-square-text"
                viewBox="0 0 16 16"
                style={{ margin: '0', marginLeft: '3px' }}
              >
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
              </svg>
            </SecondaryBtnModalTable>
          </Col>
        </Row>

        <section style={{ marginTop: '25px' }}>
          <Row>
            <Col>
              <H5Styled style={{ marginBottom: '5px' }}>Contratto</H5Styled>
            </Col>
          </Row>
          <AgreementDetailTable
            data={[data]}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            profile={profile}
          />
        </section>

        <section style={{ marginTop: '25px' }}>
          <Row>
            <Col>
              <H5Styled style={{ marginBottom: '5px' }}>
                Lista documenti e schede che compongono il contratto
              </H5Styled>
            </Col>
          </Row>
          <AgreementAttachmentsTable
            data={data?.attachments}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            profile={profile}
          />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AgreementDetailsPresentational;
