import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import {
  BtnModalTable,
  BtnOutlineGreen,
  BtnOutlineRed,
  SecondaryBtnModalTable,
} from '../../../styled-components';
import { useAlert } from 'react-alert';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';

function ActionsField(props) {
  const { data, handleFileListView, assemblyMode, fetchAgreements } = props;

  const [{ token, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation('CONDOS');

  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const toggleDeleteFileModal = () => {
    setDeleteFileModal(!deleteFileModal);
  };

  const history = useHistory();

  return (
    <React.Fragment>
      <div className="flex-check">
        <div
          style={{ cursor: 'pointer' }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="currentColor"
            class="bi bi-box-arrow-down"
            viewBox="0 0 16 16"
            style={{ color: 'black' }}
          >
            <path
              fill-rule="evenodd"
              d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"
            />
          </svg>
        </div>

        <div
          style={{
            cursor: data.uploadedBy !== 'EXTRA' ? 'pointer' : '',
            marginLeft: '10px',
            color: data.uploadedBy !== 'EXTRA' ? 'black' : 'grey',
          }}
        >
          <Link to={`/agreements-details/${data._id}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              style={{ color: 'black' }}
              fill="currentColor"
              class="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ActionsField;
