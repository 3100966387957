import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DateTimeField, Field } from '../../commons/DataTable';
import BadgeField from './BadgeField';

function AgreementDetailTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  profile,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Nome Contratto" source="name" className="text-center" />
      <Field title="Tipologia" source="type.name" className="text-center" />
      <Field title="Attività" source="activity.name" className="text-center" />
      <BadgeField
        title="Stato Contratto"
        source="status"
        className="text-center"
      />
      <DateTimeField
        title="Data creazione"
        source="createdAt"
        format="DD/MM/YYYY"
      />
      <DateTimeField
        title="Ultima Modifica"
        source="updatedAt"
        format="DD/MM/YYYY"
      />
      <Field title="PSI/POS" source="psiPos" className="text-center" />
    </DataTable>
  );
}

export default AgreementDetailTable;
