import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { BtnOutlineGreen, BtnOutlineRed } from '../../../styled-components';

function ActionsField(props) {
  const { data, deleteFileConfirm, handleFileListView } = props;

  const [{ profile = {} } = {}] = useAuth();
  const [t] = useTranslation('CONDOS');

  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const toggleDeleteFileModal = () => {
    setDeleteFileModal(!deleteFileModal);
  };

  return (
    <React.Fragment>
      <div className="flex-check">
        <div
          style={{ cursor: 'pointer' }}
          className="mx-2 px-2"
          onClick={(event) => {
            event.stopPropagation();
            handleFileListView(data.file.key);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="currentColor"
            class="bi bi-box-arrow-down"
            viewBox="0 0 16 16"
            style={{ color: 'black' }}
          >
            <path
              fill-rule="evenodd"
              d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"
            />
          </svg>
        </div>

        <div
          style={{
            cursor: data.uploadedBy === 'EXTRA' ? 'pointer' : '',
            marginLeft: '10px',
            color: data.uploadedBy === 'EXTRA' ? 'black' : 'grey',
          }}
          onClick={(event) => {
            event.stopPropagation();
            if (data.uploadedBy === 'EXTRA') {
              toggleDeleteFileModal();
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="currentColor"
            class="bi bi-trash"
            viewBox="0 0 16 16"
            className="delete-icon"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
          </svg>
        </div>
      </div>

      <Modal
        isOpen={deleteFileModal}
        toggle={toggleDeleteFileModal}
        className="new-condo-modal"
      >
        <ModalHeader toggle={toggleDeleteFileModal}>
          {t('FILES:DELETE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            deleteFileConfirm({
              documentId: data._id,
              key: data.file.key,
            });
            toggleDeleteFileModal();
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <Row>
                  <Col sm="12">
                    <p className="text-bold mb-2" id="delete-modal-text">
                      Sei sicuro di voler cancellare questo file?
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed
              type="button"
              className="uppercase"
              onClick={toggleDeleteFileModal}
            >
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ActionsField;
