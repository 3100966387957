import React, { useEffect, useState } from 'react';
import { Button, Col, CustomInput, FormGroup, Row } from 'reactstrap';
import SearchInput from '../../../commons/SearchInput';
import { ContPaginationPage } from '../../../styled-components';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import AddContractStepper from './AddContractStepper';
import { uploadSuppAdminAgreement } from '../../../lib/fetch/agreements';
import axios from 'axios';
import AgreementsTable from './AgreementsTable';
import { ADMIN_TYPES } from '../../../App';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const SupplierAdminAgreementsPresentational = ({
  activities,
  documentTypes,
  agreementTypes,
  suppliers,
  fetchSuppliers,
  documents,
  totalDocuments,
  limitDocuments,
  offsetDocuments,
  onFilterDocuments,
  onSelectChange,
  onSelectAll,
  selectedAll,
  selectedDocuments,
  handleFileListView,
  handleDelete,
  fetchDocuments,
}) => {
  const [{ token, profile = {} } = {}] = useAuth();
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [agreements, setAgreements] = useState({});
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    name: withDefault(StringParam, ''),
  });

  const { limit, offset, name } = query;

  // Fetch Contratti
  const fetchAgreements = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements`,
      {
        headers: { Authorization: token },
        params: { ...query, agreementType: 'SUPPLIER_ADMIN' },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;

    setAgreements(data.agreements);
    setTotal(+headers['x-total-count']);
  };

  // Caricamento nuovo contratto
  const uploadAgreements = async (newAgreement, toggle) => {
    showLoading();

    const { error, data } = await uploadSuppAdminAgreement({
      data: newAgreement,
      token,
    });

    hideLoading();

    if (error) {
      toggle();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchAgreements();
    fetchDocuments();
    hideLoading();
    toggle();
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  };

  useEffect(() => {
    fetchAgreements();
  }, [limit, offset, name]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.name && queryDiff.name !== name) || // first search
      (name && queryDiff.name === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  return (
    <>
      <Row className="search-add-cont m-t-20 d-flex" style={{ rowGap: '10px' }}>
        <Col sm="12" md="6" lg="3" xl="3">
          <SearchInput
            query={{ search: query && query.name }}
            onFilter={({ search }) => onFilter({ name: search })}
            label={t('SEARCH:NAME')}
          />
        </Col>

        {ADMIN_TYPES.includes(profile.type) && (
          <Col sm="12" md="12" lg="6" xl="3" className="ml-auto">
            <AddContractStepper
              profile={profile}
              activities={activities}
              documentTypes={documentTypes}
              agreementTypes={agreementTypes}
              suppliers={suppliers}
              documents={documents}
              onFilter={onFilterDocuments}
              total={totalDocuments}
              limit={limitDocuments}
              offset={offsetDocuments}
              onSelectChange={onSelectChange}
              onSelectAll={onSelectAll}
              selectedAll={selectedAll}
              selectedDocuments={selectedDocuments}
              fetchSuppliers={fetchSuppliers}
              uploadAgreements={uploadAgreements}
            />
          </Col>
        )}
      </Row>

      <section style={{ marginTop: '10px' }}>
        <AgreementsTable
          data={agreements}
          total={total}
          offset={offset}
          limit={limit}
          onFilter={onFilter}
          profile={profile}
          handleFileListView={handleFileListView}
          handleDelete={handleDelete}
          fetchAgreements={fetchAgreements}
        />

        <ContPaginationPage>
          <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

          <FormGroup>
            <CustomInput
              type="select"
              className="pagination-select"
              id="perPage"
              name="perPage"
              value={query.limit}
              onChange={({ target: { value: limit } }) => {
                onFilter({ limit });
              }}
            >
              {generatePaginationOptions()}
            </CustomInput>
          </FormGroup>
        </ContPaginationPage>
      </section>
    </>
  );
};

export default SupplierAdminAgreementsPresentational;
