import axios from 'axios';

export const getSignedUrl = async (key, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/signed-url`,
      {
        headers: { Authorization: token },
        params: { key },
      }
    );

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getAllProfileFiles = async (id, limit, offset, token, active) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/documents/${id}`,
      params: { limit, offset, active },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deleteFile = async ({ documentId, token }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/documents/${documentId}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deleteCondoFile = async ({ id, key, field, token }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/condos/${id}/files`,
      data: { key, field },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
