import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BtnModalTable } from '../../styled-components';

function ActionsFieldRegister(props) {
  const [t] = useTranslation('ACTIVITIES');

  const { data, profile } = props;

  return (
    <React.Fragment>
      <div className="flex-check">
        <Link to={`#`}>
          <BtnModalTable type="button" className="mx-2 px-2">
            {t('REGISTER')}
          </BtnModalTable>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default ActionsFieldRegister;
