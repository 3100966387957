import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import {
  BtnModalTable,
  BtnOutlineGreen,
  BtnOutlineRed,
  SecondaryBtnModalTable,
} from '../../../styled-components';
import { useAlert } from 'react-alert';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ADMIN_TYPES } from '../../../App';

function ActionsField(props) {
  const {
    data,
    deleteFileConfirm,
    handleFileListView,
    fetchAgreements,
  } = props;

  const [{ token, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation('CONDOS');

  const [deleteFileModal, setDeleteFileModal] = useState(false);

  const toggleDeleteFileModal = () => {
    setDeleteFileModal(!deleteFileModal);
  };

  const history = useHistory();

  return (
    <React.Fragment>
      <div className="flex-check">
        <div
          style={{ cursor: 'pointer' }}
          onClick={(event) => {
            event.stopPropagation();
            // handleFileListView(data.file.key);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="currentColor"
            class="bi bi-box-arrow-down"
            viewBox="0 0 16 16"
            style={{ color: 'black' }}
          >
            <path
              fill-rule="evenodd"
              d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"
            />
          </svg>
        </div>

        {ADMIN_TYPES.includes(profile.type) && (
          <div
            style={{
              cursor: data.uploadedBy !== 'EXTRA' ? 'pointer' : '',
              marginLeft: '10px',
              color: data.uploadedBy !== 'EXTRA' ? 'black' : 'grey',
            }}
          >
            <Link to={`/agreements-editor/${data._id}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                style={{ color: 'black' }}
                class="bi bi-link-45deg"
                viewBox="0 0 16 16"
              >
                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
              </svg>
            </Link>
          </div>
        )}

        <div
          style={{
            cursor: data.uploadedBy !== 'EXTRA' ? 'pointer' : '',
            marginLeft: '10px',
            color: data.uploadedBy !== 'EXTRA' ? 'black' : 'grey',
          }}
        >
          <Link to={`/agreements-details/${data._id}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              style={{ color: 'black' }}
              fill="currentColor"
              class="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
          </Link>
        </div>

        {ADMIN_TYPES.includes(profile.type) && (
          <div
            style={{
              cursor: data.uploadedBy !== 'EXTRA' ? 'pointer' : '',
              marginLeft: '10px',
              color: data.uploadedBy !== 'EXTRA' ? 'black' : 'grey',
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (data.uploadedBy !== 'EXTRA') {
                toggleDeleteFileModal();
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
              className="delete-icon"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
          </div>
        )}
      </div>

      <Modal
        isOpen={deleteFileModal}
        toggle={toggleDeleteFileModal}
        className="new-condo-modal"
      >
        <ModalHeader toggle={toggleDeleteFileModal}>
          {t('FILES:DELETE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            deleteFileConfirm({
              agreementId: data._id,
            });
            toggleDeleteFileModal();
            fetchAgreements();
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <Row>
                  <Col sm="12">
                    <p className="text-bold mb-2" id="delete-modal-text">
                      Sei sicuro di voler cancellare questo file?
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed
              type="button"
              className="uppercase"
              onClick={toggleDeleteFileModal}
            >
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ActionsField;
