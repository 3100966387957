import './MyRoyalties.scss';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Label, Row } from 'reactstrap';
import {
  CurrencyField,
  DataTable,
  DateTimeField,
  PaidBadgeWithDate,
  Field,
} from '../../commons/DataTable';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import TotalRows from './TotalTr';
import {
  BtnModalTable,
  ContFilterYear,
  ContPaginationPage,
} from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import variables from '../../variables';
import ExportIAFCSVField from '../../commons/DataTable/ExportIAFCSVField';
import AddPaymentDateField from '../../commons/DataTable/AddPaymentDateField';
import { resendEmailBonus } from '../../lib/fetch/bonuses';
import { useAuth } from '../../commons/Auth';
import ModifyBonusModal from '../ProfileDetail/FinancialReports/ModifyBonusModal';
import { useAlert } from 'react-alert';

const IAFPresentational = ({
  data,
  query,
  total,
  onFilter,
  subjects,
  addPaymentDate,
  handleUpdateBonus,
}) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit, admin } = query;
  const [{ token, isAdmin } = {}] = useAuth();
  const net = useMemo(() => data.reduce((acc, elem) => elem.amount + acc, 0), [
    data,
  ]);
  const alert = useAlert();

  async function handleReinviaEmail(id) {
    const { error, data } = await resendEmailBonus({
      token,
      _id: id,
    });

    if (error instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }
    alert.success(t('COMMON:SUCCESS_RESEND_BONUS'));
  }

  function generateFields() {
    const fields = [
      <Field key={1} title="Numero" source="number" />,
      <Field key={2} title="Rif. Fattura Amministratore" source="invoiceRef" />,
      <Field key={3} title="Resposnabile" source="profile.0.businessName" />,
      <DateTimeField key={4} title="Data" source="date" />,
      <CurrencyField key={5} title="Importo" source="amount" />,
      <DateTimeField key={6} title="Data Pagamento" source="paymentDate" />,
      <AddPaymentDateField
        key={7}
        title="Stato pagamento"
        onAddPaymentDate={addPaymentDate}
      />,
      <ModifyBonusModal
        key={8}
        title="Modifica"
        onConfirm={handleUpdateBonus}
      />,
      <BtnModalTable
        key={9}
        type="button"
        title="Reinvia Email"
        className="mx-2 px-2"
        source="_id"
        onClick={(ev) => {
          ev.stopPropagation();
          handleReinviaEmail(ev.target.value);
        }}
      >
        Reinvia Email
      </BtnModalTable>,
      <ExportIAFCSVField key={9} title={t('COMMON:CSV_EXPORT')} />,
    ];
    return fields;
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:IAF_EMITTED')}
            icon={
              <IconInvoices fill={variables.white} style={{ height: '25px' }} />
            }
          />

          <Row className="search-add-cont">
            {/* <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>Filtro Responsabile</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="profileId"
                        name="type"
                        onChange={({ target: { value: admin } }) =>
                          onFilter({ admin })
                        }
                      >
                        <option value="">Tutti</option>
                        {subjects.map((subject) => (
                          <option value={subject._id} key={subject._id}>
                            {subject.businessName}
                          </option>
                        ))}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col> */}
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH:BY_IAF_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.invoiceNumber }}
                onFilter={({ search }) => onFilter({ invoiceNumber: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
              />
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={Number(limit)}
            onFilter={onFilter}
            emptyText={t('COMMON:NO_DATA')}
            bottomRow={<TotalRows totals={{ net }} />}
          >
            {generateFields()}
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default IAFPresentational;
