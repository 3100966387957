import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DateTimeField, Field } from '../../commons/DataTable';
import BadgeField from './BadgeField';
import UploadedByField from './UploadedByField';

function AgreementAttachmentsTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  profile,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Nome File" source="file.name" className="text-center" />
      <Field title="Tipologia" source="type.name" className="text-center" />
      <UploadedByField
        title="Caricato da"
        source="uploadedBy"
        className="text-center"
      />
      <DateTimeField
        title="Data creazione"
        source="createdAt"
        format="DD/MM/YYYY"
      />
    </DataTable>
  );
}

export default AgreementAttachmentsTable;
