import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../commons/Auth';
import AgreementEditorPresentational from './AgreementsEditorPresentational';
import './AgreementsEditor.scss';

const AgreementsEditor = ({ profile }) => {
  const { id: agreementId } = useParams();
  const [t] = useTranslation('AGREEMENTS');
  const [{ token, isAdmin } = {}] = useAuth();

  return <AgreementEditorPresentational profile={profile} />;
};

export default AgreementsEditor;
