import React from 'react';
import { useTranslation } from 'react-i18next';
import { BtnModalTable, HeaderCollapse } from '../../styled-components';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const ActivityRow = ({ data }) => {
  const [t] = useTranslation('ACTIVITIES');

  return (
    <>
      <Row className="align-center activity-row p-3">
        <Col xs="8">
          <span>{data.name}</span>
        </Col>
        <Col xs="4" className="text-right">
          <Link
            to={{
              pathname: `/activities-detail/${data._id}`,
              state: { activityName: data.name },
            }}
          >
            <BtnModalTable type="button" className="mx-2 px-2">
              {t('INFO_ACTIVITY')}
            </BtnModalTable>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default ActivityRow;
