import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import { Col, Row } from 'reactstrap';
import {
  BtnRadiusWhite,
  ContFlexLeft,
  H4Styled,
} from '../../styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import variables from '../../variables';
import CKEditor from 'ckeditor4-react';

const AgreementEditorPresentational = ({
  data,
  total,
  offset,
  limit,
  onFilter,
  profile,
  query,
}) => {
  const [t] = useTranslation('AGREEMENTS');

  const [content, setContent] = useState('');

  const handleEditorChange = (event) => {
    const editorData = event.editor.getData();
    setContent(editorData);
    console.log('Editor data:', editorData);
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:AGREEMENTS')}
            icon={<IcnDocument fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <Row style={{ marginBottom: '10px' }}>
          <Col sm="12" md="6">
            <ContFlexLeft>
              <Link to={`/agreements`}>
                <BtnRadiusWhite>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>
              </Link>
              <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
            </ContFlexLeft>
          </Col>
        </Row>

        <section>
          <Row>
            <Col sm="0" md="1"></Col>
            <Col sm="12" md="10">
              <CKEditor
                style={{ visibility: 'default' }}
                initData="<p>Write your content here...</p>"
                onChange={handleEditorChange}
                config={{
                  toolbar: [
                    ['Bold', 'Italic', 'Underline'],
                    ['Link', 'Unlink'],
                  ],
                  licenseKey: '',
                  resize_enabled: false,
                  height: 600,
                }}
              />
            </Col>
            <Col sm="0" md="1"></Col>
          </Row>
        </section>
      </div>
    </div>
  );
};

export default AgreementEditorPresentational;
