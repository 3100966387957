import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { ContPaginationPage } from '../../../styled-components';
import { useAuth } from '../../../commons/Auth';
import { DataTable, Field } from '../../../commons/DataTable';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import ContractsCheckbox from './ContractsCheckbox';
import { useAlert } from 'react-alert';

const INITAL_CONTRACT_FORM_VALUES = {
  name: '',
  activity: '',
  type: '',
  documentType: '',
  supplier: '',
  attachedFile: '',
};

const INITAL_CONTRACT_ERRORS = {
  name: false,
  activity: false,
  type: false,
  documentType: false,
};

function AddContractStepper({
  profile,
  activities,
  documentTypes,
  agreementTypes,
  suppliers,
  documents,
  onFilter,
  total,
  limit,
  offset,
  onSelectChange,
  onSelectAll,
  selectedAll,
  selectedDocuments,
  fetchSuppliers,
  uploadAgreements,
}) {
  const [t] = useTranslation('AGREEMENTS_ADMIN_SUPPLIER');
  const alert = useAlert();
  const [{ isAdmin } = {}] = useAuth();

  const [addNewContractModal, setAddNewContractModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState(INITAL_CONTRACT_FORM_VALUES);
  const [errors, setErrors] = useState(INITAL_CONTRACT_ERRORS);

  const toggleAddAgreementModal = () => {
    setCurrentStep(1);
    setFormValues(INITAL_CONTRACT_FORM_VALUES);
    setErrors(INITAL_CONTRACT_ERRORS);
    setAddNewContractModal(!addNewContractModal);
  };

  function handleContractChange() {
    const name = formValues.name;
    const activity = formValues.activity;
    const type = formValues.type;
    const supplier = formValues.supplier;
    const attachedFile = formValues.attachedFile;
    const agreementType = 'SUPPLIER_ADMIN';
    const documentType = formValues.documentType;

    let newErrors = {};
    if (formValues.attachedFile !== '' && formValues.documentType === '') {
      newErrors.documentType = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...errors, ...newErrors });
      return;
    }

    const fileSize = (attachedFile.size / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Max 20Mb');
    } else {
      const data = new FormData();
      data.append('name', name);
      data.append('activity', activity);
      data.append('type', type);
      data.append('supplier', supplier);
      data.append('file', attachedFile);
      data.append('agreementType', agreementType);
      data.append('selectedDocuments', selectedDocuments);
      data.append('documentType', documentType);

      uploadAgreements(data, toggleAddAgreementModal);
    }
  }

  const goToNextStep = () => {
    if (currentStep === 1) {
      let newErrors = {};
      if (formValues.name === '') {
        newErrors.name = true;
      }
      if (formValues.activity === '') {
        newErrors.activity = true;
      }
      if (formValues.type === '') {
        newErrors.type = true;
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors({ ...errors, ...newErrors });
        return;
      }
    }

    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const onRowClick = (row) => {
    const { _id } = row;
    onSelectChange(_id);
  };

  useEffect(() => {
    if (formValues.activity && formValues.activity !== '') {
      fetchSuppliers(formValues.activity);
    }
  }, [formValues.activity]);

  return (
    <React.Fragment>
      <Button
        color="primary"
        style={{
          margin: 0,
          fontSize: '1rem',
          width: '100%',
        }}
        onClick={(event) => {
          event.stopPropagation();
          toggleAddAgreementModal();
        }}
      >
        {t('BTN_TITLE')}
      </Button>

      <Modal
        isOpen={addNewContractModal}
        toggle={toggleAddAgreementModal}
        className="new-file-modal"
      >
        <ModalHeader
          toggle={toggleAddAgreementModal}
          style={{ paddingBottom: '0px' }}
        >
          {t('TITLE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleContractChange();
          }}
        >
          <ModalBody>
            {currentStep === 1 && (
              <>
                <div
                  style={{
                    fontSize: '14px',
                    fontFamily: '"Roboto Slab", serif',
                    textAlign: 'center',
                    marginBottom: '10px',
                  }}
                >
                  Inserisci le prime informazioni
                </div>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>{t('NAME')}</Label>
                      <Input
                        type="text"
                        name="name"
                        maxLength="50"
                        defaultValue={formValues.name}
                        placeholder={t('SELECT_NAME')}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            name: e.target.value,
                          });
                          setErrors({
                            ...errors,
                            name: false,
                          });
                        }}
                      />
                      {errors.name === true && (
                        <FormText className="error-message">
                          {t('NAME_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label>{t('ACTIVITY')}</Label>
                      <CustomInput
                        id="activity"
                        type="select"
                        name="activity"
                        value={formValues.activity}
                        placeholder={t('SELECT')}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            activity: e.target.value,
                            supplier: '',
                          });
                          setErrors({
                            ...errors,
                            activity: false,
                          });
                        }}
                        style={{
                          color: formValues.activity === '' ? '#aaa' : '#000',
                        }}
                      >
                        <option value="" className="placeholder-option">
                          Seleziona un'attività
                        </option>
                        {activities.map((activity, i) => (
                          <option key={i} value={activity._id}>
                            {activity.name}
                          </option>
                        ))}
                      </CustomInput>
                      {errors.activity === true && (
                        <FormText className="error-message">
                          {t('ACTIVITY_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label>{t('TYPE')}</Label>
                      <CustomInput
                        id="type"
                        type="select"
                        name="type"
                        value={formValues.type}
                        placeholder={t('SELECT')}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            type: e.target.value,
                          });
                          setErrors({
                            ...errors,
                            type: false,
                          });
                        }}
                        style={{
                          color: formValues.type === '' ? '#aaa' : '#000',
                        }}
                      >
                        <option value="" className="placeholder-option">
                          Seleziona il tipo attività
                        </option>
                        {agreementTypes.map((type, i) => (
                          <option key={i} value={type._id}>
                            {type.name}
                          </option>
                        ))}
                      </CustomInput>
                      {errors.type === true && (
                        <FormText className="error-message">
                          {t('TYPE_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label>{t('SUPPLIER')}</Label>
                      <CustomInput
                        id="supplier"
                        type="select"
                        name="supplier"
                        value={formValues.supplier}
                        placeholder={t('SELECT')}
                        disabled={
                          !formValues.activity && formValues.activity === ''
                        }
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            supplier: e.target.value,
                          });
                        }}
                        style={{
                          color: formValues.supplier === '' ? '#aaa' : '#000',
                        }}
                      >
                        <option value="" className="placeholder-option">
                          Seleziona un fornitore
                        </option>
                        {suppliers.map((supplier, i) => (
                          <option key={i} value={supplier._id}>
                            {supplier.businessName}
                          </option>
                        ))}
                      </CustomInput>
                      {errors.supplier === true && (
                        <FormText className="error-message">
                          {t('SUPPLIER_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            {currentStep === 2 && (
              <>
                <div
                  style={{
                    fontSize: '14px',
                    fontFamily: '"Roboto Slab", serif',
                    textAlign: 'center',
                    marginBottom: '10px',
                  }}
                >
                  Scegli gli allegati dell'amministratore e del fornitore da
                  inserire nel tuo contratto
                </div>

                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    fontFamily: '"Roboto Slab", serif',
                    textAlign: 'center',
                    marginBottom: '10px',
                  }}
                >
                  Vuoi un contrato personalizzato?{' '}
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    Richiedi una consulenza
                  </a>
                </div>
                <DataTable
                  data={documents}
                  total={total}
                  offset={offset}
                  limit={limit}
                  onFilter={onFilter}
                  emptyText={t('COMMON:NO_DATA')}
                >
                  <Field
                    title="Documento"
                    source="file.name"
                    className="text-left"
                  />
                  <Field title="Tag" source="type.name" className="text-left" />
                  <ContractsCheckbox
                    selected={selectedDocuments}
                    onSelectChange={onRowClick}
                    onClick={(e) => e.stopPropagation()}
                    title={
                      <input
                        type="checkbox"
                        checked={selectedAll}
                        onChange={({ target: { checked } }) =>
                          onSelectAll(checked)
                        }
                      />
                    }
                  />
                </DataTable>

                <ContPaginationPage>
                  <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

                  <FormGroup>
                    <CustomInput
                      type="select"
                      className="pagination-select"
                      id="perPage"
                      name="perPage"
                      value={limit}
                      onChange={({ target: { value: limit } }) => {
                        onFilter({ limit });
                      }}
                    >
                      {/* {generatePaginationOptions()} */}
                    </CustomInput>
                  </FormGroup>
                </ContPaginationPage>
              </>
            )}

            {currentStep === 3 && (
              <>
                <div
                  style={{
                    fontSize: '14px',
                    fontFamily: '"Roboto Slab", serif',
                    textAlign: 'center',
                    marginBottom: '10px',
                  }}
                >
                  Vuoi Caricare altri allegati all'interno del contratto? Puoi
                  farlo qui.
                </div>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label for="selectContract">
                        {t('FILES:CHOOSE_FILE')}
                        &nbsp;({t('FILES:MAX_UPLOAD_FILE_SIZE')})
                      </Label>
                      <CustomInput
                        type="file"
                        id="attachedFile"
                        name="attachedFile"
                        onChange={(event) =>
                          setFormValues({
                            ...formValues,
                            attachedFile: event.target.files[0],
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {formValues.attachedFile !== '' && (
                  <div
                    style={{
                      margin: '5px',
                      backgroundColor: '#F7F8FA',
                    }}
                  >
                    <Row>
                      <Col sm="12">
                        <span style={{ overflowX: 'hidden' }}>
                          {formValues.attachedFile?.name}
                        </span>
                      </Col>

                      <Col sm="12">
                        <FormGroup>
                          <Label>{t('DOCUMENT_TYPE')}</Label>
                          <CustomInput
                            id="documentType"
                            type="select"
                            name="documentType"
                            value={formValues.documentType}
                            placeholder={t('SELECT')}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                documentType: e.target.value,
                              });
                              setErrors({
                                ...errors,
                                documentType: false,
                              });
                            }}
                          >
                            <option value="">-</option>
                            {documentTypes.map((type, i) => (
                              <option key={i} value={type._id}>
                                {type.name}
                              </option>
                            ))}
                          </CustomInput>
                          {errors.type === true && (
                            <FormText className="error-message">
                              {t('DOCUMENT_TYPE_ERROR')}
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={goToPreviousStep}
              disabled={currentStep === 1}
            >
              Indietro
            </Button>
            {currentStep < 3 && (
              <Button
                color="primary"
                onClick={goToNextStep}
                disabled={currentStep === 3}
              >
                Avanti
              </Button>
            )}
            {currentStep === 3 && (
              <Button color="success" type="submit">
                Salva ed esci
              </Button>
            )}
            {/* {currentStep === 3 && (
              <Button color="success" type="submit">
                Vai al contratto
              </Button>
            )} */}
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default AddContractStepper;
