import React from 'react';
import { Col, Form, Row } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import { useTranslation } from 'react-i18next';
import Header from '../../commons/Header';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import ActivityRow from './ActivityRow';
import SearchInput from '../../commons/SearchInput';

const ActivitiesPresentational = ({
  data,
  onFilter,
  query,
  total,
  profile,
}) => {
  const [t] = useTranslation('ACTIVITIES');

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:ACTIVITIES')}
            icon={<IcnDocument fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <section>
          <Row
            className="search-add-cont m-t-20"
            style={{ rowGap: '10px', marginBottom: '10px' }}
          >
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.name }}
                onFilter={({ search }) => onFilter({ name: search })}
                label={t('SEARCH:NAME')}
              />
            </Col>
          </Row>

          {data.map((activity, index) => (
            <ActivityRow data={activity} />
          ))}
        </section>
      </div>
    </div>
  );
};

export default ActivitiesPresentational;
