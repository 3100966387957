import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import AgreementDetailsPresentational from './AgreementDetailsPresentational';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import axios from 'axios';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const AgreementsDetail = ({ profile }) => {
  const { id: agreementId } = useParams();
  const [t] = useTranslation('AGREEMENTS');
  const [{ token, isAdmin } = {}] = useAuth();

  const [agreement, setAgreement] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [totalAttachments, setTotalAttachments] = useState(0);
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
  });

  const { limit, offset } = query;

  useEffect(() => {
    fetchAgreement();
  }, [limit, offset, agreementId]);

  const fetchAgreement = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/details/${agreementId}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = {} } = response;
    setAgreement(data);
    setAttachments(data.attachments);
    setTotalAttachments(data.attachments?.length);
  };

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }

    setQuery(queryDiff);
  }

  return (
    <AgreementDetailsPresentational
      data={agreement}
      attachments={attachments}
      total={totalAttachments}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      profile={profile}
      query={query}
    />
  );
};

export default AgreementsDetail;
