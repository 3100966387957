import React from 'react';

function TypeMainteinerField({ value, maxLength }) {
  if (!value) return <>-</>;

  let extraType = false;
  let externalType = false;
  for (const contract of value) {
    if (contract.maintainer) {
      extraType = true;
    } else {
      externalType = true;
    }
  }

  if (!extraType && !externalType) {
    return <>-</>;
  } else if (extraType && !externalType) {
    return <>Extra</>;
  } else if (!extraType && externalType) {
    return <>Esterno</>;
  } else if (extraType && externalType) {
    return <>Extra - Esterno</>;
  }

  return <>-</>;
}

export default TypeMainteinerField;
