import React, { useEffect, useState } from 'react';
import ActivitiesPresentational from './ActivitiesPresentational';
import './Activity.scss';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, withDefault } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Activities = ({ profile }) => {
  const [t] = useTranslation('ACTIVITIES');
  const [{ token, isAdmin } = {}] = useAuth();

  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(0);
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    name: withDefault(StringParam, ''),
  });

  const { limit, offset, name } = query;

  useEffect(() => {
    fetchActivities();
  }, [name, limit, offset]);

  const fetchActivities = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activities`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
          name,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;

    setActivities(data.activities);
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.name && queryDiff.name !== name) || // first search
      (name && queryDiff.name === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  return (
    <ActivitiesPresentational
      data={activities}
      onFilter={onFilter}
      query={query}
      total={total}
      profile={profile}
    />
  );
};

export default Activities;
