import React from 'react';
import Badge from '../../commons/DataTableFields/Badge';

const BadgeField = ({ value, data, ...props }) => (
  <div
    className={value !== '-' && value === 'DRAFT' ? 'master-yes' : 'master-no'}
    {...props}
  >
    {value && value === 'DRAFT' ? <Badge>Bozza</Badge> : <div>-</div>}
  </div>
);

export default BadgeField;
