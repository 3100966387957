import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import Header from '../../commons/Header';
import BurgerMenu from '../../commons/BurgerMenu';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import './MyProfile.scss';
import MyProfilePresentational from './MyProfileInfo/MyProfilePresentational';
import variables from '../../variables';
import { deleteFile, getSignedUrl } from '../../lib/fetch/files';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import MyProfileCollabPresentational from './MyProfileInfo/MyProfileCollabPresentational';
import axios from 'axios';
import { Link, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import MyDocuments from './MyDocuments';
import { uploadFileByUser } from '../../lib/fetch/profiles';

function MyProfile({ history, location, match = {} }) {
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation();
  const [{ isAdmin, profile = {} } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const [adminProfile, setAdminProfile] = useState({});
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();
  const [updatedProfile, setUpdatedProfile] = useState(profile);
  const [managers, setManagers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  async function fetchAdminForCollab() {
    showLoading();

    try {
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/collaborators/collab/admin`,
        headers: {
          Authorization: token,
        },
      });

      if (response instanceof Error) {
        hideLoading();
        return { error: response };
      }

      hideLoading();
      setAdminProfile(response.data);
    } catch (error) {
      return { error };
    }
  }

  const fetchDocumentTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setDocumentTypes(data.documentTypes);
  };

  const fetchActivities = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activities`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
          name: '',
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;

    setActivities(data.activities);
  };

  useEffect(() => {
    if (profile?.type === 'ADMIN_COLLAB') {
      fetchAdminForCollab();
    }
  }, []);

  useEffect(() => {}, [adminProfile]);

  useEffect(() => {
    async function fetchManagers() {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/managers`,
        {
          headers: { Authorization: token },
        }
      );

      if (response instanceof Error) {
        return alert.error(t('COMMON:GENERIC_ERROR'));
      }

      let { data = [], headers = {} } = response;
      setManagers(data);
    }

    fetchManagers();
    fetchActivities();
    fetchDocumentTypes();
  }, []);

  if (isAdmin)
    return (
      // Admins do not have a profile
      <Redirect
        push={false}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );

  if (pathname === match.url) {
    return <Redirect to={`${match.url}/info`} />;
  }

  async function uploadFile(file, toggleAddNewFileModal) {
    showLoading();

    const { error, data } = await uploadFileByUser({
      id: profile.type === 'ADMIN_COLLAB' ? profile.adminId : profile._id,
      data: file,
      token,
    });

    if (error) {
      hideLoading();

      toggleAddNewFileModal();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setUpdatedProfile(data);
    hideLoading();
    toggleAddNewFileModal();
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  }

  async function handleDelete({ documentId }) {
    showLoading();

    const { error, data } = await deleteFile({
      documentId,
      token,
    });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setUpdatedProfile(data);
    hideLoading();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  return (
    <div className="App">
      {/* <Menu /> */}
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:MY_PROFILE')}
            icon={<IconProfile fill={variables.white} />}
          />
          <div className="m-tb-20">
            <Nav tabs>
              <NavItem>
                <NavLink
                  tag={Link}
                  to={`${match.url}/info`}
                  className={classNames({ active: tab === 'info' })}
                >
                  {t('TABS:PROFILE')}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  tag={Link}
                  to={`${match.url}/my-documents`}
                  className={classNames({ active: tab === 'my-documents' })}
                >
                  {t('TABS:MY_DOCUMENTS')}
                </NavLink>
              </NavItem>
            </Nav>

            <Switch>
              <Route
                path={`${match.url}/info`}
                component={(props) => (
                  <Card>
                    <CardBody>
                      {(profile.type !== 'ADMIN_COLLAB' && (
                        <MyProfilePresentational
                          managers={managers}
                          data={profile}
                          handleFileListView={handleFileListView}
                          activities={activities}
                        ></MyProfilePresentational>
                      )) || (
                        <MyProfileCollabPresentational
                          data={profile}
                          adminProfile={adminProfile}
                          handleFileListView={handleFileListView}
                        ></MyProfileCollabPresentational>
                      )}
                    </CardBody>
                  </Card>
                )}
              />

              <Route
                path={`${match.url}/my-documents`}
                component={(props) => (
                  <Card>
                    <CardBody>
                      <MyDocuments
                        adminProfile={adminProfile}
                        profile={
                          profile.type === 'ADMIN_COLLAB'
                            ? adminProfile
                            : updatedProfile
                        }
                        uploadFile={uploadFile}
                        handleDelete={handleDelete}
                        documentTypes={documentTypes}
                      ></MyDocuments>
                    </CardBody>
                  </Card>
                )}
              />
            </Switch>
          </div>
        </section>
      </div>
    </div>
  );
}

export default withRouter(MyProfile);
