import React, { useEffect, useState } from 'react';
import ActivitiesDetailsPresentational from './ActivitiesDetailPresentational';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useAlert } from 'react-alert';
import axios from 'axios';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const ActivitiesDetail = ({ profile }) => {
  const { id: activityId } = useParams();
  const [t] = useTranslation('ACTIVITIES');
  const [{ token, isAdmin } = {}] = useAuth();

  const [condos, setCondos] = useState([]);
  const [total, setTotal] = useState(0);
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    name: withDefault(StringParam, ''),
    vatCode: withDefault(StringParam, ''),
    maintainer: withDefault(StringParam, ''),
    condosState: withDefault(StringParam, ''),
  });

  const { limit, offset, name, vatCode, maintainer, condosState } = query;

  useEffect(() => {
    fetchCondos();
  }, [limit, offset, name, vatCode, maintainer, condosState]);

  const fetchCondos = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activities/${activityId}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          name,
          vatCode,
          maintainer,
          condosState,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setCondos(data.condos);
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.maintainer && queryDiff.maintainer !== maintainer) || // first search
      (maintainer && queryDiff.maintainer === '') || // search deleted
      (queryDiff.name && queryDiff.name !== name) || // first search
      (name && queryDiff.name === '') || // search deleted
      (queryDiff.vatCode && queryDiff.vatCode !== vatCode) || // first search
      (vatCode && queryDiff.vatCode === '') || // search deleted
      (queryDiff.condosState && queryDiff.condosState !== condosState) || // first search
      (condosState && queryDiff.condosState === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  return (
    <ActivitiesDetailsPresentational
      data={condos}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      profile={profile}
      query={query}
    />
  );
};

export default ActivitiesDetail;
