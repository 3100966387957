import React from 'react';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { Link, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../commons/Footer';
import './Agreements.scss';
import SupplierCondoAgreements from './SupplierCondoAgreements';
import SupplierAdminAgreements from './SupplierAdminAgreements';

const Agreements = (props) => {
  const [t] = useTranslation('AGREEMENTS');

  const { history, location, match = {} } = props;
  const { url = '' } = match;
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();

  if (pathname === url) {
    return <Redirect to={`${url}/supplier-condo`} />;
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:AGREEMENTS')}
            icon={<IcnDocument fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <section className="m-tb-20">
          <Nav tabs>
            <NavItem>
              <NavLink
                tag={Link}
                to={`${match.url}/supplier-condo`}
                className={classNames({ active: tab === 'supplier-condo' })}
              >
                {t('SUPPLIER_CONDO')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                tag={Link}
                to={`${match.url}/supplier-admin`}
                className={classNames({ active: tab === 'supplier-admin' })}
              >
                {t('SUPPLIER_ADMIN')}
              </NavLink>
            </NavItem>
          </Nav>

          <Switch>
            <Route
              path={`${match.url}/supplier-condo`}
              component={(props) => <SupplierCondoAgreements {...props} />}
            />

            <Route
              path={`${match.url}/supplier-admin`}
              component={(props) => <SupplierAdminAgreements {...props} />}
            />
          </Switch>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default withRouter(Agreements);
