import React from 'react';

function UploadedByField({ value, maxLength, data }) {
  if (!value) return <>-</>;

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  return (
    <>
      {value}
      <p style={{ color: '#8A8C8D', margin: '0' }}>
        {data.profile?.emailReference}
      </p>
    </>
  );
}

export default UploadedByField;
